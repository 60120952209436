import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "argos-brand-voice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#argos-brand-voice",
        "aria-label": "argos brand voice permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Argos' brand voice`}</h2>
    <p>{`Argos understands the people they're writing to. They walk the same streets, binge the same shows, and see things the same way.`}</p>
    <p>{`When it comes down to it, Argos are plugged-in to their customers. When writing for Argos, you should:`}</p>
    <h3>{`Plug into the moment`}</h3>
    <p>{`Argos want to be there for all of life's moments and they bring them to life by focusing on the details in their copy.`}</p>
    <h3>{`Plug into the mood`}</h3>
    <p>{`Argos' copy is upbeat and optimistic, but they know when to tone down their sunny side if the reader isn't feeling the same way or the time isn't right.`}</p>
    <h3>{`Plug into the conversation`}</h3>
    <p>{`Argos' writing feels like they're on the same page as their customers. So they use natural, conversational language, like how you'd speak to a friend.`}</p>
    <h3>{`Plug into the channel`}</h3>
    <p>{`Argos makes sure the message they write works for the medium - whether it's online, on social or outdoor. They think about how words and pictures work together and how much reading people are prepared to do on that channel. And, if they get the luxury of space, Argos help their reader navigate and still find what they need.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "how-to-plug-into-the-moment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-plug-into-the-moment",
        "aria-label": "how to plug into the moment permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to plug into the moment`}</h2>
    <p>{`Focus on the small details to give Argos' customers a clearer picture of whatever you're writing about.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">
    We can deliver to the Scottish Islands, Isles of Scilly and the Isle of Man
    so long as it’s something small (like ink cartridges for your printer or a
    new toaster.){' '}
  </Do>
  <Dont title="Not like this" mdxType="Dont">
    We can only deliver certain products to the Scottish Islands, Isles of
    Scilly and the Isle of Man.
  </Dont>
    </Guideline>
    <p>{`Give clear instructions and encourage customers to act in the moment.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">
    <strong>Forgotten your password?</strong>
    It’s easy done. Pop the email address you registered with into the box and we’ll
    send you a link to reset it.
  </Do>
  <Dont title="Not like this" mdxType="Dont">
    <strong>Forgotten your password?</strong>
    You can reset your password once you give us your email address.
  </Dont>
    </Guideline>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">Get in touch</Do>
  <Dont title="Not like this" mdxType="Dont">Contact details</Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "how-to-plug-into-the-mood",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-plug-into-the-mood",
        "aria-label": "how to plug into the mood permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to plug into the mood`}</h2>
    <p>{`Think about how the customer is feeling and build your writing around it. It's important to give customers the information they need as quickly as possible.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">
    <strong>What if I can't make my Argos card payment?</strong>
    Don’t worry, you’ve got options. Give us a call on X and we’ll talk you through
    them.
  </Do>
  <Dont title="Not like this" mdxType="Dont">
    <strong>What if I can’t make my Argos Card payment?</strong>
    We totally understand. Life is complicated, and circumstances can change without
    warning. If you’re having difficulty making a payment, just give us a call on
    xxx and one of our friendly advisors can talk through your options.
  </Dont>
    </Guideline>
    <p>{`Argos can have a playful side, but don't let it in the way of getting the message across.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">We’ll deliver your order as soon as we can.</Do>
  <Dont title="Not like this" mdxType="Dont">
    Woo hoo! A warm, well-deserved, full-of-welly delivery will be arriving
    soon.
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "how-to-plug-into-the-conversation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-plug-into-the-conversation",
        "aria-label": "how to plug into the conversation permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to plug into the conversation`}</h2>
    <p>{`Use the language Argos' customers would use, like you're talking to a friend.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">
    <strong>If we’ve got it wrong, let’s make it right</strong>
    We're sorry about this. Can we grab some details from you so we can sort it out?
  </Do>
  <Dont title="Not like this" mdxType="Dont">
    <strong>If something’s wrong, let us make it right</strong>
    We're really sorry that you’re having trouble, Kathryn. Can we get a few order
    details from you so that we can resolve this?
  </Dont>
    </Guideline>
    <p>{`Use contractions like it’s, we’ve and we’ll to set a casual tone with Argos' customers.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">We've sent your order and it's on its way.</Do>
  <Dont title="Not like this" mdxType="Dont">
    We have sent your order and it is coming your way.
  </Dont>
    </Guideline>
    <p>{`Include the kind of phrases that you'd actually use in day-to-day conversation.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">
    <strong>Prefer to collect in-store?</strong>
    No problem. You can use our store locator to see which store is closest to you.
  </Do>
  <Do title="Like this" mdxType="Do">
    Just tell us the product you've got your eye on and we'll let you know when
    it's next in stock
  </Do>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "how-to-plug-into-the-channel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-plug-into-the-channel",
        "aria-label": "how to plug into the channel permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to plug into the channel`}</h2>
    <p>{`Keep your writing short and sweet. Stop as soon as you've got the main message across.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">Order today. Get it today. Easy.</Do>
  <Dont title="Not like this" mdxType="Dont">
    With our Fast Track service, you can get items you’ve ordered today
    delivered today.
  </Dont>
    </Guideline>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">
    <strong>Going to miss this delivery?</strong>
    No problem. You can rebook your delivery easily.
  </Do>
  <Dont title="Not like this" mdxType="Dont">
    <strong>Going to miss this delivery?</strong>
    As this is a large package, we'll have to rebook your delivery. You can rebook
    your delivery by following this link to our rebook a delivery page.
  </Dont>
    </Guideline>
    <p>{`Use descriptive headings to break up longer blocks of text so customers can easily scan your writing.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Like this" mdxType="Do">
    <strong>Where do you deliver?</strong>
    Absolutely everywhere in mainland UK and Northern Ireland.
    <strong>How do I check?</strong>
    Just pop your postcode into the box on the page of the product you’ve got your
    eye on.
  </Do>
  <Dont title="Not like this" mdxType="Dont">
    We can deliver absolutely everywhere in mainland UK and Northern Ireland. If
    you want to double check that we can deliver to your address, just pop your
    postcode into the box on the page of the product you've got your eye on.
  </Dont>
    </Guideline>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our copy team are on hand to answers questions or help with your project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      